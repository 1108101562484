import React, { ReactNode } from 'react';
import Footer from './Footer';
import Header from './Header';

// Define the props interface
interface LayoutProps {
  children: ReactNode;
}

// Functional component with typed props
const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow mt-[-100px]">{children}</main>
        <Footer />
      </div>
    );
};

export default Layout;