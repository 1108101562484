import React from 'react';
import * as HeroIcons from '@heroicons/react/24/solid';

// Define the props for the Button component
interface ButtonProps {
  onClick?: () => void; // Make onClick optional
  className?: string;
  label: string;
  icon?: keyof typeof HeroIcons; // New prop for the icon name
  href?: string; // New prop for the URL
  variant?: 'primary' | 'secondary' | 'tertiary'; // New prop for button variant
  umamiData?: string; //umam eventtracking data-umami-event="{umamiData}"


}

// Create the Button component
const Button: React.FC<ButtonProps> = ({ 
  onClick, 
  className = '', 
  label, 
  icon, 
  href, 
  variant = 'primary', 
  umamiData // Add umamiData to destructured props
}) => {
  // Dynamically get the icon component if an icon name is provided
  const IconComponent = icon ? HeroIcons[icon] : null;

  // Define classes for each variant
  const primaryClasses = `px-6 py-3 justify-center bg-orange-600 text-text rounded-full flex items-center hover:bg-orange-500 active:bg-orange-400 transition-colors duration-200 ${className}`;
  const secondaryClasses = `px-6 py-3 justify-center border-2 box-border border-orange-600 text-orange-600 rounded-full flex items-center hover:border-orange-400 hover:text-orange-400 active:border-orange-200 hover:border-2 active:text-orange-200 transition-all duration-200 ${className}`;
  const tertiaryClasses = `px-3 py-3 justify-center background-none text-orange-600 rounded-full flex items-center hover:text-orange-400 active:text-orange-200 transition-colors duration-200 ${className}`;

  // Determine the classes based on the variant
  const buttonClasses = variant === 'secondary' ? secondaryClasses : variant === 'tertiary' ? tertiaryClasses : primaryClasses;

  // Render an anchor element if href is provided, otherwise render a button
  return href ? (
    <a href={href} className={buttonClasses} data-umami-event={umamiData}>
      {IconComponent && <IconComponent className="w-6 h-6 mr-2" />}
      {label}
    </a>
  ) : (
    <button onClick={onClick} className={buttonClasses} data-umami-event={umamiData}>
      {IconComponent && <IconComponent className="w-6 h-6 mr-2" />}
      {label}
    </button>
  );
};

export default Button;
