import axios from 'axios';

// Create an axios instance with default configuration
const tmdbApi = axios.create({
  baseURL: 'https://api.themoviedb.org/3',
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_TMDB_READ_ACCESS_TOKEN}`,
    'Content-Type': 'application/json;charset=utf-8',
  },
});

// Example function to fetch popular movies
export const getPopularMovies = async () => {
  try {
    const response = await tmdbApi.get('/movie/popular');
    return response.data;
  } catch (error) {
    console.error('Error fetching popular movies:', error);
    throw error;
  }
};

// Fetch movie details from tmdb
export const getMovieDetails = async (movieId: string) => {
  try {
    const response = await tmdbApi.get(`/movie/${movieId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching movie details:', error);
    throw error;
  }
};

// Fetch the highest-rated English poster for a specific movie
export const getHighestRatedMoviePoster = async (movieId: string) => {
  try {
    // Fetch all images for the specified movie
    const response = await tmdbApi.get(`/movie/${movieId}/images`);
    
    // Filter for English posters, then sort them by vote_average in descending order
    const sortedPosters = response.data.posters
      .filter((poster: any) => poster.iso_639_1 === 'en' || poster.iso_639_1 === null)
      .sort((a: any, b: any) => b.vote_average - a.vote_average);
    
    // Return the highest-rated English poster (first in the sorted array)
    return sortedPosters[0] || null;
  } catch (error) {
    console.error(`Error fetching highest-rated English poster for movie ${movieId}:`, error);
    throw error;
  }
};

// Fetch the highest-rated backdrop for a specific movie
export const getHighestRatedMovieBackdrop = async (movieId: string) => {
  try {
    const response = await tmdbApi.get(`/movie/${movieId}/images`);
    const sortedBackdrops = response.data.backdrops.sort((a: any, b: any) => b.vote_average - a.vote_average);
    return sortedBackdrops[0];
  } catch (error) {
    console.error(`Error fetching highest-rated backdrop for movie ${movieId}:`, error);
    throw error;
  }
};

// Fetch the highest-rated English logo for a specific movie
export const getHighestRatedMovieLogo = async (movieId: string) => {
  try {
    const response = await tmdbApi.get(`/movie/${movieId}/images`);
    const sortedLogos = response.data.logos
      .filter((logo: any) => logo.iso_639_1 === 'en' || logo.iso_639_1 === null)
      .sort((a: any, b: any) => b.vote_average - a.vote_average);
    return sortedLogos[0] || null;
  } catch (error) {
    console.error(`Error fetching highest-rated English logo for movie ${movieId}:`, error);
    throw error;
  }
};

// Fetch movie details from tmdb by IMDb ID
export const getMovieDetailsByImdbId = async (imdbId: string) => {
  try {
    const response = await tmdbApi.get(`/find/${imdbId}`, {
      params: {
        external_source: 'imdb_id'
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching movie details for IMDb ID ${imdbId}:`, error);
    throw error;
  }
};

// Fetch detailed movie information by TMDB movie ID
export const getDetailedMovieInfo = async (tmdbMovieId: number) => {
  try {
    const response = await tmdbApi.get(`/movie/${tmdbMovieId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching detailed movie info for TMDB movie ID ${tmdbMovieId}:`, error);
    throw error;
  }
};

// Fetch release dates and extract MPAA rating
export const getMovieCertification = async (tmdbMovieId: number) => {
  try {
    const response = await tmdbApi.get(`/movie/${tmdbMovieId}/release_dates`);
    const usRelease = response.data.results.find((release: any) => release.iso_3166_1 === 'US');
    const certification = usRelease?.release_dates.find((date: any) => date.certification)?.certification || 'Not Rated';
    return certification;
  } catch (error) {
    console.error(`Error fetching certification for TMDB movie ID ${tmdbMovieId}:`, error);
    throw error;
  }
};