import React from 'react';
//import html2canvas from 'html2canvas';
import Button from './Button'; // Import the Button component


interface ShareMovieProps {
  poster: string;
  backdrop: string;
  title: string;
  onClose: () => void;
}

const ShareMovie: React.FC<ShareMovieProps> = ({ poster, backdrop, title, onClose }) => {
  const loadImage = async (src: string): Promise<HTMLImageElement> => {
    try {
      const response = await fetch(src, { mode: 'cors' });
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = blobUrl;
        img.onload = () => {
          URL.revokeObjectURL(blobUrl); // Clean up the blob URL
          resolve(img);
        };
        img.onerror = (e) => {
          URL.revokeObjectURL(blobUrl); // Clean up the blob URL
          reject(new Error(`Image load error: ${src}, ${e}`));
        };
      });
    } catch (error) {
      throw new Error(`Image load error: ${src}, ${error}`);
    }
  };

  const generateImage = async (aspectRatio: string) => {
    try {
      console.log('Starting image generation');
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (!context) throw new Error('Failed to get canvas context');

      console.log('Loading images');
      const [backdropImg, posterImg] = await Promise.all([
        loadImage(backdrop),
        loadImage(poster),
      ]);
      console.log('Images loaded successfully');

      if (aspectRatio === '1:1') {
        canvas.width = 1080;
        canvas.height = 1080;
      } else if (aspectRatio === '9:16') {
        canvas.width = 1080;
        canvas.height = 1920;
      }

      // Draw the backdrop image
      context.drawImage(backdropImg, 0, 0, canvas.width, canvas.height);

      // Calculate the position and size for the poster image
      const posterWidth = canvas.width / 2;
      const posterHeight = (posterImg.height / posterImg.width) * posterWidth;
      const posterX = (canvas.width - posterWidth) / 2;
      const posterY = (canvas.height - posterHeight) / 2;

      // Draw the poster image
      context.drawImage(posterImg, posterX, posterY, posterWidth, posterHeight);

      // Create a download link for the generated image
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = `${title}-${aspectRatio}.png`;

      // For mobile devices, prompt the user to save the image manually
      if (navigator.userAgent.match(/iPhone|iPad|iPod|Android/i)) {
        // Create an image element to display the generated image
        const img = document.createElement('img');
        img.src = dataUrl;
        img.alt = `${title} - ${aspectRatio}`;
        img.style.width = '100%';
        img.style.height = 'auto';

        // Create a container to hold the image and instructions
        const container = document.createElement('div');
        container.style.position = 'fixed';
        container.style.top = '0';
        container.style.left = '0';
        container.style.width = '100%';
        container.style.height = '100%';
        container.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
        container.style.display = 'flex';
        container.style.flexDirection = 'column';
        container.style.alignItems = 'center';
        container.style.justifyContent = 'center';
        container.style.zIndex = '1000';

        // Add instructions for saving the image
        const instructions = document.createElement('p');
        instructions.textContent = 'Tap and hold the image to save it to your camera roll.';
        instructions.style.color = 'white';
        instructions.style.marginBottom = '20px';
        instructions.style.textAlign = 'center';

        // Add a close button
        const closeButton = document.createElement('button');
        closeButton.textContent = 'Close';
        closeButton.style.marginTop = '20px';
        closeButton.style.padding = '10px 20px';
        closeButton.style.backgroundColor = '#ff0000';
        closeButton.style.color = 'white';
        closeButton.style.border = 'none';
        closeButton.style.borderRadius = '5px';
        closeButton.style.cursor = 'pointer';
        closeButton.onclick = () => {
          document.body.removeChild(container);
        };

        // Append elements to the container
        container.appendChild(instructions);
        container.appendChild(img);
        container.appendChild(closeButton);

        // Append the container to the body
        document.body.appendChild(container);
      } else {
        link.click();
      }
    } catch (error) {
      console.error('Error generating image:', error);
      alert(`An error occurred while generating the image: ${(error as Error).message}`);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative bg-slate-900 p-12 rounded-lg shadow-lg text-center w-11/12 md:w-1/2">
        <h2 className="text-2xl mb-4 mt-8">Share "{title}"</h2>
        <Button onClick={() => generateImage('1:1')} label="Download 1:1 Image" variant="secondary" className="mb-2 w-full mt-12" />
        <Button onClick={() => generateImage('9:16')} label="Download 9:16 Image" variant="secondary" className="mb-2 w-full" />
        <Button
          onClick={onClose}
          label="Close"
          variant="tertiary"
          icon="XMarkIcon"
          className="absolute top-4 right-4"
        />
      </div>
    </div>
  );
};

export default ShareMovie;