// App.tsx
import React, { useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from 'react-router-dom';
import MovieList from './components/MovieList';
import MovieDetails from './components/MovieDetails';
import Layout from './components/Layout';
import { loadMoviesForDate } from './utils/loadMovies';
import Why from './components/Why';
import Calendar from './components/Calendar';

// This component will handle the filtered movie list
const FilteredMovieList: React.FC = () => {
  const { filterType, filterValue } = useParams<{ filterType: string; filterValue: string }>();

  // Construct the filter object based on the filterType
  const filter = filterType === 'year' 
    ? { yearCurated: filterValue }
    : filterType === 'theme'
    ? { theme: filterValue }
    : filterType === 'director'
    ? { director: filterValue }
    : filterType === 'writer'
    ? { writer: filterValue }
    : {};

  return <MovieList filter={filter} />;
};

// Wrapper component to extract the imdbID from the URL and pass it to MovieDetails
const MovieDetailsWrapper: React.FC = () => {
  const { imdbID } = useParams<{ imdbID: string }>();
  return <MovieDetails movieId={imdbID || ''} />;
};

const App: React.FC = () => {
  const [movies, setMovies] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        // Adjust the date to Mountain Time Zone (MDT)
        const today = new Date();
        const mountainTimeOffset = 6 * 60 * 60 * 1000; // 7 hours in milliseconds
        const mountainDate = new Date(today.getTime() - mountainTimeOffset)
          .toISOString()
          .split('T')[0];
        console.log('Mountain Time Date:', mountainDate);
        const moviesForToday = await loadMoviesForDate(mountainDate);
        console.log('Movies for today:', moviesForToday);
        setMovies(moviesForToday);
      } catch (err) {
        console.error('Error loading movies:', err);
        setError('Failed to load movies for today');
      } finally {
        setIsLoading(false);
      }
    };

    fetchMovies();
  }, []);

  // Memoize the movies array
  const memoizedMovies = useMemo(() => movies, [movies]);

  const TodayRoute = () => {
    if (isLoading) {
      return (
        <div className="text-center text-2xl mt-72 text-text">
          <span className="inline-block animate-spin">💀</span> Loading...
        </div>
      );
    }

    if (error) {
      return <div className="text-center text-2xl mt-72 text-red-500">{error}</div>;
    }

    if (movies.length === 0) {
      return (
        <>
          <div className="text-center mt-40 mb-0 bg-background rounded-xl p-8 container mx-auto py-24 w-4/5">
            <h2 className="text-3xl font-bold text-text mb-1"><span className="animate-pulse">💔</span> No Movie Scheduled Tonight</h2>
            <p className="text-xl text-slate-400 mb-4">
              There are no movies scheduled for tonight. Check back in October for daily curations.
            </p>
          </div>
          <MovieList filter={{ yearCurated: "2024" }} />
        </>
      );
    }

    console.log('Rendering TodayRoute with movies:', movies);

    return movies.length === 1 ? (
      <>
        <MovieDetails movieId={movies[0]} isTodayRoute={true} />
        <MovieList filter={{ yearCurated: "2024" }} />
      </>
    ) : (
      <MovieList movieIds={memoizedMovies} />
    );
  };

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate to="/today" />} />
          <Route path="/all" element={<MovieList />} />
          <Route path="/:filterType/:filterValue" element={<FilteredMovieList />} />
          <Route path="/movies/:imdbID" element={<MovieDetailsWrapper />} />
          <Route path="/today" element={<TodayRoute />} />
          <Route path="/why" element={<Why />} /> 
          <Route path="/calendar" element={<Calendar />} /> 
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;