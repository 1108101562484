import React from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

// Define the Calendar component
const Calendar: React.FC = () => {
  return (
    <div className="container mx-auto px-8 py-16 text-text mt-20 lg:px-48">
      <h1 className="text-4xl font-bold mb-4">Download Calendar</h1>
      <p className="text-3xl mb-4">
        Google Calendar, ICS, or a sick image. 
      </p>
      <img src="/img/Axeslasher_HalloweenHorror2024_Cover_1080x1080thumb.png" alt="Axeslasher Halloween Horror Calendar 2024" className="mb-4 w-full md:w-2/3 lg:w-3/4 rounded-md my-8" />
      <h2 className="text-3xl font-bold mb-4 mt-12">Google Calendar</h2>
      <p className="text-xl">
        Access our Google Calendar and witness the horrifying lineup that will possess your October. Add it to your own calendar to ensure you don't miss a single night of fright!
      </p>
      <ul className="list-disc list-inside text-xl mb-4 mt-4">
        <li className="mb-2">
          <a className="text-orange-500" href="https://calendar.google.com/calendar/embed?src=axeslasher.com_11tvi12jqjkrquq0tlkf7j86rk%40group.calendar.google.com&ctz=America%2FDenver" target='blank' rel="noopener noreferrer">View Google Calendar</a>
        </li>
      </ul>
      <h2 className="text-3xl font-bold mb-4 mt-12">ICS</h2>
      <p className="text-xl">
      Download our ICS file and import the horrifying lineup directly into your calendar app. Perfect for Apple or iOS users, this ensures you won't miss a single night of fright throughout October!
      </p>
      <ul className="list-disc list-inside text-xl mb-4 mt-4">
        <li className="mb-2">
          <a className="text-orange-500" href="/data/AxeslasherHalloweenHorrorMarathon.ics" download>Download the ICS File</a>
        </li>
      </ul>
      <h2 className="text-3xl font-bold mb-4 mt-12">Sick Images</h2>
      <p className="text-xl">
      Download these spine-chilling images to keep the Halloween Horror Marathon lineup at your fingertips! Perfect for sharing on social media or as a quick reference. Spread the fear and let your followers know what cinematic nightmares await!
      </p>
      <ul className="list-none text-xl mb-4 mt-4 grid grid-cols-1 md:grid-cols-2 gap-12 text-center mt-8">
        <li className="mb-2">
          <a className="text-orange-500 flex flex-col items-center" href="/img/Axeslasher_HalloweenHorror2024_Cover_1080x1080.png" download>
            <img src="/img/Axeslasher_HalloweenHorror2024_Cover_1080x1080thumb.png" alt="Cover (1:1)" className="w-full rounded-md mb-2" />
            <span className="flex items-center">
              <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
              Download Cover (1:1)
            </span>
          </a>
        </li>
        <li className="mb-2">
          <a className="text-orange-500 flex flex-col items-center" href="/img/Axeslasher_HalloweenHorror2024_Calendar_1080x1080.png" download>
            <img src="/img/Axeslasher_HalloweenHorror2024_Calendar_1080x1080thumb.png" alt="Calendar (1:1)" className="w-full rounded-md mb-2" />
            <span className="flex items-center">
              <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
              Download Calendar (1:1)
            </span>
          </a>
        </li>
        <li className="mb-2">
          <a className="text-orange-500 flex flex-col items-center" href="/img/Axeslasher_HalloweenHorror2024_Cover_1080x1920.png" download>
            <img src="/img/Axeslasher_HalloweenHorror2024_Cover_1080x1920thumb.png" alt="Cover (9:16)" className="w-full rounded-md mb-2" />
            <span className="flex items-center">
              <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
              Download Cover (9:16)
            </span>
          </a>
        </li>
        <li className="mb-2">
          <a className="text-orange-500 flex flex-col items-center" href="/img/Axeslasher_HalloweenHorror2024_Calendar_1080x1920.png" download>
            <img src="/img/Axeslasher_HalloweenHorror2024_Calendar_1080x1920thumb.png" alt="Calendar (9:16)" className="w-full rounded-md mb-2" />
            <span className="flex items-center">
              <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
              Download Calendar (9:16)
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Calendar;