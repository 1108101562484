import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { Bars3Icon, XMarkIcon, FilmIcon, QuestionMarkCircleIcon, QueueListIcon } from '@heroicons/react/24/outline';

// Define the Header component
const Header: React.FC = () => {
  // State to manage menu open/close
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  // Function to toggle menu state
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to handle route change on select
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    try {
      const selectedRoute = event.target.value;
      if (selectedRoute) {
        navigate(selectedRoute);
        setIsMenuOpen(false); // Close the menu
      }
    } catch (error) {
      console.error('Error handling select change:', error);
    }
  };

  return (
    <header className="bg-gradient-to-b from-slate-950 from-0% to-80% bg-opacity-40 text-white py-4 absolute top-0 left-0 w-full z-50 sticky">
      <div className="container mx-auto px-8">
        <div className="flex justify-between items-center">
          {/* Hamburger menu */}
          <button
            onClick={toggleMenu}
            className="text-white bg-slate-800 bg-opacity-20 hover:bg-slate-800 focus:outline-none transition-all duration-200 z-50 p-2 border border-slate-500 rounded-lg"
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            data-umami-event={isMenuOpen ? 'Close menu' : 'Open menu'}
          >
            {isMenuOpen ? (
              <XMarkIcon className="h-6 w-6" />
            ) : (
              <Bars3Icon className="h-6 w-6" />
            )}
          </button>

          {/* Add your navigation items here */}
          {/* For example: */}
          <nav className={`${isMenuOpen ? 'fixed inset-0 flex items-center justify-center bg-slate-950 bg-opacity-90 z-40' : 'hidden'}`}>
            <ul className="text-center font-bold">
              <li>
                <a href="/" data-umami-event="Main Nav: Tonight's Movie" className="block py-4 text-2xl hover:text-orange-500 transition-colors duration-300 flex items-center justify-center">
                  <FilmIcon className="h-6 w-6 mr-2" />
                  Tonight's Movie
                </a>
              </li>
              <li>
                <a href="/year/2024/" data-umami-event="Main Nav: 2024 Watchlist" className="block py-4 text-2xl hover:text-orange-500 transition-colors duration-300 flex items-center justify-center">
                  <QueueListIcon className="h-6 w-6 mr-2" />
                  2024 Watchlist
                </a>
              </li>
              <Button href="/calendar" className="w-full md:w-auto mt-4" label="Download Calendar" icon="ArrowDownTrayIcon" variant="secondary" umamiData='Main Nav: Calendar Button'/>

              
              <hr className="border-slate-500 border-1 w-full mx-auto mt-10 mb-6" />
              <li>
                <a href="/why" data-umami-event="Main Nav: Why"className="block py-4 text-2xl hover:text-orange-500 transition-colors duration-300 flex items-center justify-center">
                  <QuestionMarkCircleIcon className="h-6 w-6 mr-2" />
                  Why?
                </a>
              </li>
              <span className="text-l mt-2 block text-slate-300">Browse Past Watchlists</span>
              {/* Dropdown for past lists by year */}
              <select
                className="block w-full py-3 px-3 mt-3 border border-slate-500 bg-slate-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                onChange={handleSelectChange}
                defaultValue=""
              >
                <option value="" disabled>2023, 2022, etc.</option>
                <option value="/year/2023/">2023</option>
                <option value="/year/2022/">2022</option>
                <option value="/year/2021/">2021</option>
                <option value="/year/2020/">2020</option>
                <option value="/year/2019/">2019</option>
                <option value="/year/2018/">2018</option>
                <option value="/year/2017/">2017</option>
                <option value="/year/2016/">2016</option>
                <option value="/year/2015/">2015</option>
                <option value="/all">All</option>
              </select>


            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;

// Ensure the file is treated as a module
export {};
