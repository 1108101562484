import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMovieByImdbId, getAllMovies, AirtableMovie } from '../services/airtable-services';
import { getMovieDetailsByImdbId, getDetailedMovieInfo, getMovieCertification } from '../services/tmdb-api';
import Button from './Button';
import ShareMovie from './ShareMovie';
import october2024Data from '../data/october-2024.json'; // Import the JSON data

interface MovieDetailsProps {
  movieId: string;
  isTodayRoute?: boolean; // Add this prop
}

const MovieDetails: React.FC<MovieDetailsProps> = ({ movieId, isTodayRoute = false }) => {
  console.log('MovieDetails rendered with isTodayRoute:', isTodayRoute);

  const navigate = useNavigate();
  const [movie, setMovie] = useState<AirtableMovie | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [validImdbIds, setValidImdbIds] = useState<string[]>([]);
  const [mpaaRating, setMpaaRating] = useState<string | null>(null);
  const [tagline, setTagline] = useState<string | null>(null);
  const [showShareMovie, setShowShareMovie] = useState<boolean>(false);

  // Function to get the night and movie order from the JSON data
  const getNightAndMovieOrder = (imdbID: string) => {
    let cumulativeMovieOrder = 0;
    for (const [date, ids] of Object.entries(october2024Data)) {
      const movieIndex = ids.indexOf(imdbID);
      if (movieIndex !== -1) {
        cumulativeMovieOrder += movieIndex + 1;
        const night = parseInt(date.split('-')[2], 10); // Convert day part to number to strip leading zero
        return { night, movieOrder: cumulativeMovieOrder };
      }
      cumulativeMovieOrder += ids.length;
    }
    return { night: '', movieOrder: 0 };
  };

  // Get night and movie order if the route is /today
  const { night, movieOrder } = isTodayRoute ? getNightAndMovieOrder(movieId) : { night: '', movieOrder: 0 };

  useEffect(() => {
    const fetchMovieData = async () => {
      if (!movieId) {
        setError('Invalid movie ID');
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      try {
        const movieData = await getMovieByImdbId(movieId);
        setMovie(movieData);

        try {
          const tmdbData = await getMovieDetailsByImdbId(movieId);
          if (tmdbData.movie_results.length > 0) {
            const tmdbMovieId = tmdbData.movie_results[0].id;
            const detailedMovieInfo = await getDetailedMovieInfo(tmdbMovieId);

            const mpaaRating = await getMovieCertification(tmdbMovieId);
            setMpaaRating(mpaaRating);

            const tagline = detailedMovieInfo.tagline;
            setTagline(tagline);
          } else {
            setMpaaRating(null);
            setTagline(null);
          }
        } catch (tmdbError) {
          console.error('Error fetching TMDB data:', tmdbError);
          setMpaaRating(null);
          setTagline(null);
        }
      } catch (error) {
        setError('Error fetching movie data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchMovieData();
  }, [movieId]);

  // Fetch valid IMDb IDs when the component mounts
  useEffect(() => {
    const fetchValidImdbIds = async () => {
      try {
        const movies = await getAllMovies();
        const ids = movies.map((movie: AirtableMovie) => movie.imdbID);
        setValidImdbIds(ids);
      } catch (error) {
        console.error('Error fetching valid IMDb IDs:', error);
      }
    };

    fetchValidImdbIds();
  }, []);

  // Show loading message while movie data is being fetched
  if (isLoading) {
    return (
      <div className="text-center text-2xl mt-72 text-text">
        <span className="inline-block animate-spin">💀</span> Loading...
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-2xl mt-72 text-red-600">💀 {error}</div>;
  }

  if (!movie) {
    return <div className="text-center text-2xl mt-72 text-text">Movie not found 💔 🪓</div>;
  }

  // This function handles the click event for the WatchButton
  // const handleWatchClick = () => {
  //   // Show the ShareMovie component
  //   setShowShareMovie(true);
  // };

  // This function handles the click event for the RecommendButton
  const handleRecommendClick = async () => {
    if (validImdbIds.length === 0) {
      console.error('No valid IMDb IDs available');
      return;
    }

    setIsLoading(true); // Show loading indicator

    try {
      // Randomly select a valid IMDb ID
      const randomIndex = Math.floor(Math.random() * validImdbIds.length);
      const newImdbID = validImdbIds[randomIndex];
      navigate(`/movies/${newImdbID}`);
    } catch (error) {
      console.error('Error navigating to new movie:', error);
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };

  return (
    <div className="relative bg-slate-950 text-text flex-grow">
      {showShareMovie && movie && (
        <ShareMovie
          poster={movie.Poster}
          backdrop={movie.Backdrop}
          title={movie['Movie Title']}
          onClose={() => setShowShareMovie(false)}
        />
      )}
      {movie.Backdrop && (
        <div className="absolute inset-0 z-0">
          <img
            src={movie.Backdrop}
            alt={`${movie['Movie Title']} backdrop`}
            className="w-full object-cover opacity-90 h-[666px]"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-slate-950 via-slate-950/50 to-transparent h-[666px]"></div>
        </div>
      )}
      {isTodayRoute && (
        <div className="absolute top-16 lg:top-[80px] left-0 w-full text-center text-slate-200 p-4">
          <h2 className="text-2xl lg:text-4xl font-bold tracking-tight">Tonight's Movie</h2>
          {night && movieOrder && (
            <p className="text-base text-slate-300 lg:mt-1 ">
              Night {night}, Movie {movieOrder}
            </p>
          )}
        </div>
      )}
      <div className="relative z-10 container mx-auto px-6 py-8 flex flex-col items-center lg:flex-row lg:items-start lg:pt-48 lg:pb-4 lg:px-24">
        {movie.Poster && (
          <div className="mb-4 mt-32 lg:mt-0 lg:mr-8 xl:w-1/3 flex-shrink-0">
            <img
              src={movie.Poster}
              alt={`${movie['Movie Title']} poster`}
              className="w-64 lg:w-80 xl:w-96 2xl:w-[30rem] max-w-full rounded-lg shadow-lg object-cover"
            />
          </div>
        )}
        <div className="flex flex-col items-center lg:items-center px-6 lg:px-8 w-full lg:w-2/3">
          {movie.Logo ? (
            <div className="mb-4 text-center">
              <img src={movie.Logo} alt={`${movie['Movie Title']} logo`} className="w-64 my-2 hidden lg:block mx-auto" />
              <h1 className="text-4xl font-bold mb-1 lg:sr-only">{movie['Movie Title']}</h1> {/* Render title at small sizes */}
              <p className="text-sm text-slate-300 mt-0 text-center">
                {mpaaRating && (
                  <span className='outline outline-1 rounded-sm px-2 mr-2 text-xs font-semibold text-slate-400'>{mpaaRating}</span>
                )}
                {movie['Release Year']} • {movie.Runtime} min
              </p>
            </div>
          ) : (
            <div className="mb-4 text-center lg:text-left">
              <h1 className="text-4xl font-bold mb-1">{movie['Movie Title']}</h1>
              <p className="text-sm text-slate-300 mt-0 text-center lg:text-left">
                <span className='outline outline-1 rounded-sm px-2 mr-2 text-xs font-semibold text-slate-400'>{mpaaRating}</span> {movie['Release Year']} • {movie.Runtime} min
              </p>
            </div>
          )}
          {tagline && <p className="font-serif text-slate-400 mb-4 mt-0 lg:text-slate-300 text-lg text-center shadow-md lg:text-xl font-semibold lg:mb-6 lg:my-0 w-full lg:w-auto italic">"{tagline}"</p>}
          <h2 className="text-xl lg:text-2xl font-bold mb-2 lg:mb-1 w-full tracking-tight">Overview</h2>
          <p className="text-lg lg:text-2xl font-normal mb-8 lg:mb-6 w-full lg:w-auto">{movie.Plot}</p>
          <div className="grid grid-cols-3 xl:grid-cols-5 gap-3 mb-4 w-full lg:w-auto text-base">
            {movie.Director && movie.Director.length > 0 && (
              <div>
                <span className="font-semibold block">Director:</span>
                {movie.Director.map((director, index) => (
                  <React.Fragment key={director}>
                    <a
                      href={`/director/${director}`}
                      className="text-orange-500 hover:text-orange-300 hover:underline active:text-orange-200 transition-all duration-300"
                    >
                      {director}
                    </a>
                    {index < movie.Director.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </div>
            )}
            {movie.Writer && movie.Writer.length > 0 && (
              <div>
                <span className="font-semibold block">Writer:</span>
                {movie.Writer.map((writer, index) => (
                  <React.Fragment key={writer}>
                    <a
                      href={`/writer/${writer}`}
                      className="text-orange-500 hover:text-orange-300 hover:underline active:text-orange-200 transition-all duration-300"
                    >
                      {writer}
                    </a>
                    {index < movie.Writer.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </div>
            )}
            <div>
              <span className="font-semibold block">Genres:</span> {movie.Genre.join(', ')}
            </div>
            {movie.Themes && movie.Themes.length > 0 && (
              <div>
                <span className="font-semibold block">Themes:</span>
                {movie.Themes.map((theme, index) => (
                  <React.Fragment key={theme}>
                    <a
                      href={`/theme/${theme}`}
                      className="text-orange-500 hover:text-orange-300 hover:underline active:text-orange-200 transition-all duration-300"
                    >
                      #{theme}
                    </a>
                    {index < movie.Themes.length - 1 && ', '}
                  </React.Fragment>
                ))}
              </div>
            )}
            <div>
              <span className="font-semibold block">Years Curated:</span>
              {movie['Years Curated'].map((year, index) => (
                <React.Fragment key={year}>
                  <a
                    href={`/year/${year}`}
                    className="text-orange-500 hover:text-orange-300 hover:underline active:text-orange-200 transition-all duration-300"
                  >
                    {year}
                  </a>
                  {index < movie['Years Curated'].length - 1 && ', '}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className='w-full mt-6 md:px-32 lg:px-0 grid grid-cols-1 xl:grid-cols-2 gap-2'>
            {/* <Button onClick={handleWatchClick} className="w-full md:w-auto" label="I watched this" icon="EyeIcon" variant="primary" /> */}
            {isLoading ? (
              <div className="w-full md:w-auto text-center text-lg text-slate-300">Loading...</div>
            ) : (
              <Button onClick={handleRecommendClick} className="w-full md:w-auto" label="Recommend Another" icon="ArrowPathIcon" variant="secondary" umamiData='Movie Details: Recommend Another' />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieDetails;