// MovieList.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getAllMovies, AirtableMovie } from '../services/airtable-services';
import october2024Data from '../data/october-2024.json'; // Import the JSON data

// Define the filter type
interface MovieFilter {
  yearCurated?: string;
  theme?: string;
  director?: string;
  writer?: string;
}

interface MovieListProps {
  movieIds?: string[];
  filter?: MovieFilter;
}

const MovieList: React.FC<MovieListProps> = ({ movieIds, filter = {} }) => {
  const [movies, setMovies] = useState<AirtableMovie[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Use useCallback to memoize the fetchMovies function
  const fetchMovies = useCallback(async () => {
    setIsLoading(true); // Start loading
    try {
      console.log('Fetching movies...'); // Debugging: Log when fetching starts
      const allMovies = await getAllMovies();
      console.log('All movies fetched:', allMovies); // Debugging: Log all movies fetched

      const filteredMovies = movieIds
        ? allMovies.filter(movie => movieIds.includes(movie.imdbID))
        : allMovies.filter(movie => {
            if (filter.yearCurated) {
              return Array.isArray(movie['Years Curated']) && movie['Years Curated'].includes(filter.yearCurated);
            }
            if (filter.theme) {
              return Array.isArray(movie.Themes) && movie.Themes.includes(filter.theme);
            }
            if (filter.director) {
              return Array.isArray(movie.Director) && movie.Director.includes(filter.director);
            }
            if (filter.writer) {
              return Array.isArray(movie.Writer) && movie.Writer.includes(filter.writer);
            }
            return true;
          });

      console.log('Filtered movies:', filteredMovies); // Debugging: Log filtered movies

      // Sort the filtered movies by the 'Movie Order' field in ascending order
      const sortedMovies = filteredMovies.sort((a, b) => {
        const aOrder = Array.isArray(a['Movie Order']) ? Number(a['Movie Order'][0]) : 0;
        const bOrder = Array.isArray(b['Movie Order']) ? Number(b['Movie Order'][0]) : 0;
        return aOrder - bOrder;
      });

      console.log('Sorted movies:', sortedMovies); // Debugging: Log sorted movies

      setMovies(sortedMovies);
      setError(null); // Clear any previous errors
    } catch (err) {
      console.error('Error fetching data from Airtable:', err); // Debugging: Log the error
      setError('Error fetching data from Airtable');
    } finally {
      setIsLoading(false); // End loading
    }
  }, [filter.director, filter.theme, filter.writer, filter.yearCurated, movieIds]);

  // Use useEffect to call fetchMovies only when movieIds or filter changes
  useEffect(() => {
    fetchMovies();
  }, [fetchMovies]);

  console.log('Rendering MovieList', { isLoading, error, moviesCount: movies.length });

  // Show loading message while movie data is being fetched
  if (isLoading) {
    return (
      <div className="text-center text-2xl mt-72 text-text">
        <span className="inline-block animate-spin">💀</span> Loading...
      </div>
    );
  }

  if (error) {
    return <div className="text-center text-2xl mt-72 text-red-600">💀 {error}</div>;
  }

  const getTitle = () => {
    if (movieIds) return "Tonight's Movies";
    if (filter.yearCurated) return `${filter.yearCurated}'s Watchlist`;
    if (filter.theme) return `#${filter.theme}`;
    if (filter.director) return `Directed by ${filter.director}`;
    if (filter.writer) return `Written by ${filter.writer}`;
    return 'All Horror Movies';
  };

  // Function to get the night and movie order from the JSON data
  const getNightAndMovieOrder = (imdbID: string) => {
    let cumulativeMovieOrder = 0;
    for (const [date, ids] of Object.entries(october2024Data)) {
      const movieIndex = ids.indexOf(imdbID);
      if (movieIndex !== -1) {
        cumulativeMovieOrder += movieIndex + 1;
        const night = parseInt(date.split('-')[2], 10); // Convert day part to number to strip leading zero
        return { night, movieOrder: cumulativeMovieOrder };
      }
      cumulativeMovieOrder += ids.length;
    }
    return { night: '', movieOrder: 0 };
  };

  return (
    <div className="mx-auto container py-24 px-8">
      <h1 className="text-4xl font-bold mb-1 text-gray-100 mt-12">
        {getTitle()}
      </h1>
      <h2 className="text-xl font-bold mb-12 text-gray-100">
        {movies.length} Movies
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-6">
        {movies.map((movie, index) => {
          const uniqueKey = `${movie.imdbID}-${movie['Movie Title']}-${index}`;
          const { night, movieOrder } = getNightAndMovieOrder(movie.imdbID);
          return (
            <div key={uniqueKey} className="bg-slate-950 rounded-md shadow-md">
              <div className="p-4">
                {filter.yearCurated === '2024' && (
                  <p className="text-slate-300 text-sm mb-0">
                    Night {night}, Movie {movieOrder}
                  </p>
                )}
                <Link to={`/movies/${movie.imdbID}`}>
                  <img 
                    src={movie.Poster} 
                    alt={`${movie['Movie Title']} poster`} 
                    className="w-full h-64 object-scale-down"
                  />
                  <h3 className="font-semibold text-slate-100 text-base mt-2 display-block leading-none">
                    {movie['Movie Title']}
                    <span className="text-slate-500 font-normal text-sm ml-1"> {movie['Release Year']}</span>
                  </h3>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MovieList;