import React from 'react';

// Define the Why component
const Why: React.FC = () => {
  return (
    <div className="container mx-auto px-8 py-16 text-text mt-40 lg:px-64">
      <h1 className="text-4xl font-bold mb-4">Why?</h1>
      <p className="text-3xl mb-4">
        Because sharing things you like is cool.
      </p>
      <h2 className="text-3xl font-bold mb-4 mt-6">Origin</h2>
      <p className="text-xl">
        Since 2015, Axeslasher has been curating a list of Horror movies to watch each night in October. These lists have traditionally been curated and posted through Instagram, but frankly I'm tired of building my house in someone else's yard. So instead I built this to give our fans an answer to the question "What movie should I watch tonight?"
      </p>
      <p className="text-xl mt-4">
        This collection is 260 movies that Axeslasher enjoys and wants to share with you. If you can't find something you like, you're probably already dead.
      </p>
      <h2 className="text-3xl font-bold mb-4 mt-6">How To Use</h2>
      <p className="text-xl">
        Every night in October, a new movie will be highlighted on the homepage. If the movie looks good to you, you'll have to seek it out. Most selections are available on streaming services, try looking the movie up on JustWatch to see if you can stream it. If the movie isn't available for streaming, well bucko that means I chose it from my personal archive and suggest you start building your own. 
      </p>
      <p className="text-xl mt-4">
        If you don't like the movie curated that night, you can use the "Recommend Another" button to see another movie. Or you can browse moveies by Director, Writer, Theme, or Year Curated.
      </p>
      <h2 className="text-3xl font-bold mb-4 mt-6">How To Contribute</h2>
      <p className="text-xl">
        Easy. Use movies.axeslasher.com to find something good to watch every night in October. Share the lins with your friends. <a className="text-orange-500" href="https://discord.gg/0w65z385WGGK7vY6" rel="noopener noreferrer" target="_blank">Join us in our Discord</a> to discuss each night's curation. 
      </p>
      <h3 className="text-xl font-bold mb-4 mt-12">Ackowledgements</h3>
      <p className="text-md">
        Movie data provided, bregrudgingly, by <a className="text-orange-500" href="https://www.themoviedb.org/" rel="noopener noreferrer" target="_blank">The Movie Database.</a>
      </p>
      <p className="text-md">
        Backend powered by <a className="text-orange-500" href="https://airtable.com" rel="noopener noreferrer" target="_blank">Airtable</a>.
      </p>


      
    </div>
  );
};

export default Why;