import Airtable, { FieldSet, Record } from 'airtable';

// Ensure Airtable credentials are loaded
if (!process.env.REACT_APP_AIRTABLE_BASE_ID || !process.env.REACT_APP_AIRTABLE_PERSONAL_ACCESS_TOKEN) {
  throw new Error('Airtable credentials are not set');
}

// Removed sensitive console logs
console.log('NODE_ENV:', process.env.NODE_ENV);

// Initialize Airtable with your credentials
const base = new Airtable({
  apiKey: process.env.REACT_APP_AIRTABLE_PERSONAL_ACCESS_TOKEN
}).base(process.env.REACT_APP_AIRTABLE_BASE_ID || '');

// Define the table name
const TABLE_NAME = '2024 Merged Horror Movies'; // Replace with your actual table name

// Define the Movie interface based on your Airtable structure
export interface AirtableMovie {
  imdbID: string;
  'Movie Title': string;
  'Release Year': number;
  Backdrop: string;
  Director: string[];
  Genre: string[];
  Logo: string;
  'Movie Order': string[];
  'Night Shown': string[];
  Plot: string;
  Poster: string;
  Runtime: number;
  Themes: string[];
  Writer: string[];
  'Years Curated': string[];
}

// Helper function to convert Airtable FieldSet to AirtableMovie
const convertToAirtableMovie = (fields: FieldSet): AirtableMovie => ({
  imdbID: fields.imdbID as string,
  'Movie Title': fields['Movie Title'] as string,
  'Release Year': fields['Release Year'] as number,
  'Night Shown': Array.isArray(fields['Night Shown']) ? fields['Night Shown'] : [],
  'Movie Order': Array.isArray(fields['Movie Order']) ? fields['Movie Order'] : [],
  Poster: fields.Poster as string,
  Backdrop: fields.Backdrop as string,
  Logo: fields.Logo as string,
  Director: Array.isArray(fields.Director) ? fields.Director : [],
  Writer: Array.isArray(fields.Writer) ? fields.Writer : [],
  Plot: fields.Plot as string,
  Genre: Array.isArray(fields.Genre) ? fields.Genre : [],
  Runtime: fields.Runtime as number,
  Themes: Array.isArray(fields.Themes) ? fields.Themes : [],
  'Years Curated': Array.isArray(fields['Years Curated']) ? fields['Years Curated'] : [],
});

// Function to fetch all movies from Airtable
export const getAllMovies = async (): Promise<AirtableMovie[]> => {
  try {
    const records = await base(TABLE_NAME).select({
      sort: [{field: 'Movie Order', direction: 'asc'}]
    }).all();
    return records.map((record: Record<FieldSet>) => convertToAirtableMovie(record.fields));
  } catch (error) {
    console.error('Error fetching movies from Airtable:', error);
    throw error;
  }
};

// Function to fetch a single movie by imdbID
export const getMovieByImdbId = async (imdbId: string): Promise<AirtableMovie | null> => {
  try {
    const records = await base(TABLE_NAME).select({
      filterByFormula: `{imdbID} = '${imdbId}'`
    }).firstPage();
    
    if (records.length > 0) {
      return convertToAirtableMovie(records[0].fields);
    }
    return null;
  } catch (error) {
    console.error(`Error fetching movie with imdbID ${imdbId} from Airtable:`, error);
    throw error;
  }
};