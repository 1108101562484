import React from 'react';

// Define the Footer component
const Footer: React.FC = () => {
  return (
    <footer className="bg-slate-950 text-white py-8 lg:py-24 mt-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          {/* Band Logo */}
          <div className="w-full md:w-auto mb-4 md:mb-0 flex justify-center">
            <h2 className="text-2xl font-bold md:text-center">
              <a href="https://axeslasher.com" target="_blank" rel="noopener noreferrer" data-umami-event="Footer: Logo">
                <img src='/img/axeslasher_logo.svg' alt='Axeslasher Logo' className='w-32 mx-auto'/>
              </a>
            </h2>
          </div>
          {/* Navigation Links */}
          <nav className="w-full md:w-auto">
            <ul className="flex flex-wrap justify-center md:justify-end">
              <li className="mx-2 my-1">
                <a href="https://ffm.bio/axeslasher" target="_blank" rel="noreferrer" className="hover:text-orange-500 transition-colors duration-300" data-umami-event="Footer: Music">Music</a>
              </li>
              <li className="mx-2 my-1">
                <a href="https://merch.axeslasher.com/" target="_blank" rel="noreferrer" className="hover:text-orange-500 transition-colors duration-300" data-umami-event="Footer: Merch">Merch</a>
              </li>
              <li className="mx-2 my-1">
                <a href="https://www.instagram.com/axeslasher/" target="_blank" rel="noreferrer" className="hover:text-orange-500 transition-colors duration-300" data-umami-event="Footer: Instagram">Instagram</a>
              </li>
              <li className="mx-2 my-1">
                <a href="http://eepurl.com/gItb-z" target="_blank" rel="noreferrer" className="hover:text-orange-500 transition-colors duration-300" data-umami-event="Footer: Email List">Email List</a>
              </li>
              <li className="mx-2 my-1">
                <a href="https://discord.gg/0w65z385WGGK7vY6" target="_blank" rel="noreferrer" className="hover:text-orange-500 transition-colors duration-300" data-umami-event="Footer: Discord">Discord</a>
              </li>
            </ul>
          </nav>
        </div>

        {/* Copyright */}
        <div className="mt-16 text-center text-sm text-slate-500 ">
          <p><span className='font-bold'>𐕣 {new Date().getFullYear()} AXESLASHER 𐕣</span> <br /> All mistakes are on purpose and exist to make you angry specifically.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

// Ensure the file is treated as a module
export {};
